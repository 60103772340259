import React,{useEffect,useState} from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { VscGraphLine } from "react-icons/vsc";
import {  HiOutlineCurrencyDollar, HiOutlineReceiptRefund } from "react-icons/hi";
import {IoIosBusiness} from "react-icons/io"
import { BsBox } from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineUsergroupDelete } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa"
import { Link } from "react-router-dom";
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { useDispatch } from "react-redux";
import { onFetchAnalytics } from "../../Actions/BusinessAction";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const BusinessAnalytics = () => {

    const location = useLocation();
    const bid = queryString.parse(location.search).bid;
    console.log(bid,"bid")
    const dispatch= useDispatch()

    const [analytics,setAnalytics] = useState([])
    const [loader,setLoader] = useState(false)

    const fetchAnalytics=()=>{
        let data = {bid:bid}
        setLoader(true)
        dispatch(onFetchAnalytics(data,setAnalytics,setLoader))
    }
    useEffect(()=>{
        fetchAnalytics()
    },[])

    return (
        <>
          <TitleBar title="Coupon" />
            <Navbar activeNav={"business"}/>
            <section className="siteWrap">
                <div className="container">
                    <div className="business-wrap">


                        <ViewBusinessNav active={"analytics"} />
                        {loader? <div className="loader-sec">
                         <div className="loader"></div>
                       </div>:<>
                        <div className="business-top">
                            <h2>Analytics</h2>
                        </div>
                        <div className="stat-box-wrap">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><HiOutlineCurrencyDollar /></span>
                                            <h6>Gross</h6>
                                            <h2>{analytics.gross}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><IoIosBusiness/></span>
                                            <h6>Business</h6>
                                            <h2>{analytics.links}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><BsBox /></span>
                                            <h6>Products</h6>
                                            <h2>{analytics.products}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><AiOutlineUsergroupAdd /></span>
                                            <h6>Total Customers</h6>
                                            <h2>{analytics.totalCustomers}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><HiOutlineCurrencyDollar /></span>
                                            <h6>Paid Customers </h6>
                                            <h2>{analytics.paidcustomer}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><AiOutlineUsergroupDelete /></span>
                                            <h6>Unpaid Customers</h6>
                                            <h2>{analytics.unpaidcustomer}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="statt-box">
                                        <div>
                                            <span><HiOutlineReceiptRefund /></span>
                                            <h6>Refunds</h6>
                                            <h2>{analytics.refunds}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="business-top mt-5">
                            <h2>Graphs & Generate Report in PDF</h2>
                        </div>

                        <div className="stat-box-wrap">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="statt-box">
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </>}               
                    </div>
                    
                </div>
            </section>
            <Footer />
        </>
    )
}

export default BusinessAnalytics;