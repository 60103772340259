import React, { useState } from 'react'
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onFetchBusinessList } from '../../Actions/BusinessAction';
import { fetchBusinessCustomer } from '../../Actions/ClientAction';
import { onFetchProducts } from '../../Actions/ProductAction';
import { onCreateInvoice } from '../../Actions/RebrandAction';
import modalCloseIcon from '../../images/modal-close.png';
import { MdErrorOutline } from "react-icons/md"
const ContentModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [businessList, setBusinessList] = useState([])
    const [productList, setProductList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [msg, setMsg] = useState("")
    const [showInvoice, setShowInvoice] = useState(false)
    const [invoiceUrl, setInvoiceUrl] = useState("")
    const [state, setState] = useState({
        business: "",
        product: "",
        customer: ""
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let product = productList.find((product) => product.id === state.product);
        setInvoiceUrl(product.url)
        dispatch(onCreateInvoice(state, setLoader, handleClose, setState, setShowInvoice))
    }
    const fetchBusiness = () => {
        dispatch(onFetchBusinessList(setBusinessList))
        dispatch(fetchBusinessCustomer({}, setCustomerList))
    }
    const modleClose = () => {
        handleClose()
        setShowInvoice(false)
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "business") {
            if (value === "createBusiness") {
                navigate('/business')
            }
            else {
                if (value !== "") {
                    setMsg("")
                }
                let data = {
                    bid: value
                }
                dispatch(onFetchProducts(data, setProductList))
                setState({
                    ...state,
                    business: value
                })
            }

        }
        if (name === "product") {
            if (value === "createProduct") {
                if (state.business !== "") {
                    navigate(`/product?bid=${state.business}`)
                } else {
                    setMsg(<><MdErrorOutline /> {"Select business first to select product!"}</>)
                }
            }
            else {
                setState({
                    ...state,
                    product: value
                })
            }
        }
        if (name === "customer") {
            if (value === "createCustomer") {
                navigate(`/customer`)
            }
            else {
                setState({
                    ...state,
                    customer: value
                })
            }
        }
    }

    const copyFunction = () => {
        let copyText;
        copyText = document.getElementById("copyUrltoCopy");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    };

    useEffect(() => {
        fetchBusiness()
    }, [])


    return (
        <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={modleClose} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                {showInvoice ?
                    <div className="shareLink">
                        <input
                            class="searchInp full"
                            type="text"
                            id="copyUrltoCopy"
                            value={`https://${invoiceUrl}`}
                            readOnly
                        />
                        <button
                            className="demoLink smBtn mt-0 ml-2"
                            onClick={() => copyFunction()}
                        >
                            Copy
                        </button>
                    </div>
                    :
                    <form className="formSec" onSubmit={handleSubmit}>
                        <div className="inpField">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label htmlFor="business">Business Name</label>
                                    <select
                                        name="business"
                                        className="inpField-inp text-light my-2"
                                        value={state.business}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" style={{ fontWeight: "bold", color: "gray" }}>Select Business</option>
                                        {businessList.length > 0 ?
                                            businessList.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                        <option value="createBusiness" style={{ background: "#1ad5ba", textAlign: "center", fontSize: "16px", fontWeight: "700", color: "#FFF", textTransform: "uppercase" }}>Create Business</option>
                                    </select>
                                    <div style={{ color: "red", position: 'relative', top: "-5px", left: "15px" }}>
                                        {msg}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <label htmlFor="">Product Name</label>
                                    <select
                                        name="product"
                                        className="inpField-inp text-light my-2"
                                        value={state.product}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" style={{ fontWeight: "bold", color: "gray" }}>Select Product</option>
                                        {productList.length > 0 ?
                                            productList.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                )
                                            })
                                            : ""}
                                        <option value="createProduct" style={{ background: "#1ad5ba", textAlign: "center", fontSize: "16px", fontWeight: "700", color: "#FFF", textTransform: "uppercase" }}>Create Product</option>
                                    </select>

                                </div>
                            </div>

                            <div className='mt-3'>
                                <label htmlFor="">Customer Name</label>
                                <select
                                    name="customer"
                                    className="inpField-inp text-light my-2"
                                    value={state.customer}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" style={{ fontWeight: "bold", color: "gray" }}>Select Customer</option>
                                    {customerList.length > 0 ?
                                        customerList.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.id}>{curElem.name}</option>
                                            )
                                        })
                                        : ""}
                                    <option value="createCustomer" style={{ background: "#1ad5ba", textAlign: "center", fontSize: "16px", fontWeight: "700", color: "#FFF", textTransform: "uppercase" }}>Create Customer</option>
                                </select>
                            </div>

                        </div>
                        <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn"> {loader ? <> Generating Invoice  <i className="fa fa-spinner fa-spin mr-2" /> </> : ' Generate Invoice'}</button>
                    </form>

                }
            </Modal.Body>
        </Modal>
    )
}

export default ContentModal