import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import ViewBusinessNav from "../Business/ViewBusinessNav";
import { Link, useNavigate } from "react-router-dom";
import { onFetchProducts, onSubmitProduct, onUnmountComProduct } from "../../Actions/ProductAction";
import { useDispatch, useSelector } from "react-redux";
import ProductRow from "./ProductRow";
import Modal from "react-bootstrap/Modal";
import modalCloseIcon from "../../images/modal-close.png";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const Product = () => {

  const location = useLocation()
  const bid = queryString.parse(location.search).bid;
  const auth = useSelector(state => state.auth)
  const businessState = useSelector(state => state.business.data)
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);
  const [data, setData] = useState({
    bid: bid,
    name: "",
    description: "",
    slug: "",
    url: ""
  });
  const [productData, setProductData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState("")
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemonPage: 10,
  });
  const [pageNumber, setPageNumber] = useState([]);

  const [state, setState] = useState({
    name: false,
    bId: false,
    mode: false,
    date: false,
    price: false,
  });
  const fetchProducts = (id) => {
    let data = {
      bid: id
    }
    setLoader(true);
    dispatch(onFetchProducts(data, setProduct, setLoader));
  };

  const handleSort = (type) => {
    let data = [...product];
    if (type === "name") {
      if (state.name) {
        data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          name: true,
        });
      }
    }
    if (type === "bId") {
      if (state.bId) {
        data.sort((a, b) => {
          if (a.id.toLowerCase() < b.id.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          bId: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.id.toLowerCase() > b.id.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          bId: true,
        });
      }
    }
    if (type === "mode") {
      if (state.mode) {
        data.sort((a, b) => {
          if (a.mode.toLowerCase() < b.mode.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          mode: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.mode.toLowerCase() > b.mode.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          mode: true,
        });
      }
    }
    if (type === "price") {
      if (state.price) {
        data.sort((a, b) => {
          if (a.price.toLowerCase() < b.price.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          price: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.price.toLowerCase() > b.price.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          price: true,
        });
      }
    }
    if (type === "date") {
      if (state.date) {
        data.sort((a, b) => {
          if (a.modified.toLowerCase() < b.modified.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          date: false,
        });
      } else {
        data.sort((a, b) => {
          if (a.modified.toLowerCase() > b.modified.toLowerCase()) {
            return 1;
          } else {
            return -1;
          }
        });
        setState({
          ...state,
          date: true,
        });
      }
    }
    setProductData(data);
  };

  useEffect(() => {
    if (bid) {
      fetchProducts(bid);
    }
  }, [bid])

  const indexOfLastTodo = pagination.currentPage * pagination.totalItemonPage;
  const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemonPage;
  const currentTodo = (search === "" && mode !== "") ||
    (search !== "" && mode === "") ||
    (search !== "" && mode !== "")
    ? productData
    : productData.slice(indexOfFirstTodo, indexOfLastTodo);

  useEffect(() => {
    if (productData.length > 0) {
      let maxLength = productData.length;
      let data = [];
      if (maxLength > 10) {
        let val = maxLength / 10;
        if (val > parseInt(val)) {
          val = val + 1;
        }
        for (let i = 1; i <= val; i++) {
          data.push(i);
        }
        setPageNumber(data);
      } else {
        setPageNumber([1]);
      }
    }
  }, [productData]);

  const handleBackward = (num) => {
    if (pageNumber[0] < num) {
      setPagination({
        ...pagination,
        currentPage: num - 1,
      });
    }
  };

  const handleClick = (num) => {
    setPagination({
      ...pagination,
      currentPage: num,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setLoader(true);
    dispatch(onSubmitProduct(data, setLoader, navigate))
  };

  const handleForward = (num) => {
    if (pageNumber[pageNumber.length - 1] > num) {
      setPagination({
        ...pagination,
        currentPage: num + 1,
      });
    }
  };

  const handleSelect = (e) => {
    const { value } = e.target;
    if (value === "") {
      setMode("");
    } else if (value === "0") {
      setMode("sandbox");
    } else if (value === "1") {
      setMode("live");
    }
  };

  useEffect(() => {
    if (search === "" && mode === "") {
      setProductData(product)
    } else {
      const val = product
        .filter((curElem) => {
          if (mode === "") {
            return curElem
          }
          let data = curElem.mode === mode;
          return data;

        })
        .filter((curElem) => {
          let data = curElem.name.toLowerCase().includes(search.toLowerCase()) ||
            curElem.slug.toLowerCase().includes(search.toLowerCase());
          if (data) {
            return curElem
          }
        })
      setProductData(val);
    }
  }, [search, product, mode])

  // unmount component
  useEffect(() => {
    return () => dispatch(onUnmountComProduct());
  }, []);

  return (
    <>
      <TitleBar title="Business" />
      <Navbar activeNav={"business"} />
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
            <ViewBusinessNav />
            <div className="business-top">
              <h2>Your Products</h2>
              <div>
                <button className="demoLink mt-0" onClick={handleShow}>
                  Create New Product <FiArrowRight />{" "}
                </button>
              </div>
            </div>
            <div className="business-list">
              <div className="business-search">
                <div className="business-search-inp">
                  <span className="searchIcon">
                    <FiSearch />
                  </span>
                  <input
                    className="inpSearch withIcon"
                    type="text"
                    name="key"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search product name"
                  />
                </div>
                <div className="business-search-inp ml-2">
                  <select
                    name="filterVal"
                    className="inpSearch"
                    onChange={handleSelect}
                  >
                    <option value="">All</option>
                    <option value="0">Sandbox</option>
                    <option value="1">Live</option>
                  </select>
                </div>
              </div>

              <div className="bListing">
                <table className="table businessTable">
                  <thead>
                    <tr>
                      <th width="100"></th>
                      <th
                        onClick={() => handleSort("name")}
                        className="cursor-pointer"
                      >
                        Name
                      </th>
                      <th
                        onClick={() => handleSort("bId")}
                        className="cursor-pointer"
                      >
                        Product ID
                      </th>
                      <th
                        onClick={() => handleSort("mode")}
                        className="cursor-pointer"
                      >
                        Mode
                      </th>
                      <th
                        onClick={() => handleSort("price")}
                        className="cursor-pointer"
                      >
                        {`Price (${auth.user.symbol})`}
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTodo.length > 0
                      ? currentTodo
                        // .filter((item) => {
                        //   return item.name
                        //     .toLowerCase()
                        //     .includes(search.key.toLowerCase());
                        // })
                        // .filter((ele) => {
                        //   if (search.filterVal === "") {
                        //     return ele;
                        //   }
                        //   return ele.mode === search.filterVal;
                        // })
                        .map((curElem, index) => {
                          return (
                            <React.Fragment key={index}>
                              <ProductRow
                                curElem={curElem}
                                fetchProducts={fetchProducts}
                              />
                            </React.Fragment>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
                {loader ? (
                  <div className="text-center my-4">
                    <i
                      className="fa fa-spinner fa-spin mr-2"
                      style={{ fontSize: "30px" }}
                    />
                  </div>
                ) : product.length === 0 ? (
                  <div className="text-center mt-4">
                    You do not have any products, Please create some products
                  </div>
                ) : (
                  ""
                )}
                <div className="tablepage">
                  <ul>
                    <li
                      onClick={() => handleBackward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronLeft style={{ color: pageNumber.length <= 1 ? "gray" : "white" }} />
                      </a>
                    </li>
                    {pageNumber.length > 0
                      ? pageNumber.map((curElem, index) => {
                        return (
                          <li
                            key={index}
                            id={curElem}
                            style={{ color: "white" }}
                            onClick={() => handleClick(curElem)}
                            className="cursor-pointer"
                          >
                            <a
                              style={
                                curElem === pagination.currentPage
                                  ? {
                                    backgroundColor: "white",
                                    color: "#400C46",
                                  }
                                  : {}
                              }
                            >
                              {curElem}
                            </a>
                          </li>
                        );
                      })
                      : ""}

                    <li
                      onClick={() => handleForward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronRight style={{ color: pageNumber.length <= 1 ? "gray" : "white" }} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal
        className="VideoModal small"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <form action="" onSubmit={(e) => onSubmit(e)}>
            <div onClick={handleClose} className="vidClose">
              <img src={modalCloseIcon} alt="" />
            </div>
            <div className="inpLabelWrap ">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inpLabel">
                    <label className="text-dark" htmlFor="">Product Name</label>
                    <input
                      className="inpLabel-inp"
                      placeholder="Your Name Here"
                      type="text"
                      name='name'
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inpLabelWrap ">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inpLabel">
                    <label className="text-dark" htmlFor="">
                      Product Description
                    </label>
                    <textarea
                      type="text"
                      className="inpLabel-inp"
                      placeholder="Add Description"
                      name="description"
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inpLabelWrap">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inpLabel">
                    <label className="text-dark" htmlFor="">URL Short</label>
                    <span className="inpLabel-inp"></span>
                    <div className="labelField">
                      <span className="text-dark">
                        https://{businessState.url}/product/
                      </span>
                      <input
                        placeholder={data.name}
                        type="text"
                        name="slug"
                        required
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">
              {loader ? <>Creating Product <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "20px" }} /></> : 'Create Product'}
            </button>
          </form>
        </Modal.Body >
      </Modal >
    </>
  );
};

export default Product;
