import React from "react";
import mac from "../../images/desk_1.png"
import mac2 from "../../images/desk_2.png"
import mac3 from "../../images/desk_3.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container-fluid">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>ALL-IN-ONE INVOICE MANAGEMENT SOLUTION</h6>
                                        <h2>Designed to make your financial operations run smoothly</h2>
                                        <p>You can easily create and send invoices, track payments, and manage your finances with ease. The software's user-friendly interface and intuitive design make it simple for you to get started and start getting paid faster</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>INCREASE EFFICIENCY WITH MULTI-CLIENT SUPPORT</h6>
                                        <h2>Manage multiple clients and customers in one dashboard</h2>
                                        <p>Simplify the process of managing invoices and payments for your agency, saving you time and reducing stress. You can manage multiple clients and customers all within the same platform, making it easier for you to track the transactions and streamline your business operations.</p>
                                        {/* <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="featureSec-left">
                                        <h6>CUSTOMIZABLE INVOICING WITH AUTOMATED PAYMENT TRACKING</h6>
                                        <h2>Keep track of payments with ease</h2>
                                        <p>Create professional and customized invoices that reflect your brand's unique style and personality. {rebrand ? rebrand.name : appName} alerts you as soon as a payment is received, and you can even set up automatic payment reminders for clients who may be running late.</p>
                                        {/* <div className="textL">Find out how the <span>Team Members Access</span> feature works</div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="featureSec-right"><img alt="" src={mac3} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;