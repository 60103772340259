import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link, useNavigate } from "react-router-dom";
import {
  onDeleteBusiness,
  onFetchBusinessList,
  onUnmountCompo,
  onCheckBusinessUrl
} from "../../Actions/BusinessAction";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import BusinessTableHeader from "./BusinessTableHeader";
import { FiArrowRight, FiSearch } from "react-icons/fi";
import { FiExternalLink, FiEdit, FiTrash2 } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import dotImg from "../../images/dots.png";
import Modal from "react-bootstrap/Modal";
import modalCloseIcon from "../../images/modal-close.png";
import BusinessDropdown from "./BusinessDropdown";

const BusinessTable = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    description: "",
    url: "",
  });

  const [businessChecker, setBusinessChecker] = useState({
    checkBusinessUrl: true,
    checkBusinessMessage: "",
  })
  const [business, setBusiness] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemonPage: 10,
  });
  const [pageNumber, setPageNumber] = useState([]);
  const [mode, setMode] = useState("");
  const [nameLength, setNameLength] = useState(0);

  const fetchBusiness = () => {
    setLoader(true);
    dispatch(onFetchBusinessList(setBusinessData, setLoader));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name === "name") {
      setNameLength(value.length);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    checkBusinessUrl("submit")
  };

  const deleteBusiness = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Deleting...",
          timer: 1000,
        });
        let data = {
          id: id,
        };
        dispatch(onDeleteBusiness(data, fetchBusiness));
      }
    });
  };

  //   Filter Mode
  const handleMode = (e) => {
    const { value } = e.target;
    if (value === "") {
      setMode("");
    } else if (value === "0") {
      setMode("sandbox");
    } else if (value === "1") {
      setMode("live");
    }
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    if (mode === "" && searchKey === "") {
      setBusiness(businessData);
    } else {
      const val = businessData
        .filter((curElem) => {
          if (mode === "") {
            return curElem;
          } else {
            // if(mode === "" || mode==="sandbox"){
            //   let data = curElem.mode === "" || curElem.mode === "sandbox";
            //   return data;
            // }
            let data = curElem.mode === mode;
            return data;
          }
        })
        .filter((curElem) => {
          let data = curElem.name
            .toLowerCase()
            .includes(searchKey.toLowerCase());
          if (data) {
            return curElem;
          }
        });
      setBusiness(val);
    }
  }, [mode, searchKey, businessData]);

  //   Pagination

  useEffect(() => {
    if (business.length > 0) {
      let maxLength = business.length;
      let data = [];
      if (maxLength > 10) {
        let val = maxLength / 10;
        if (val > parseInt(val)) {
          val = val + 1;
        }
        for (let i = 1; i <= val; i++) {
          data.push(i);
        }
        setPageNumber(data);
      } else {
        setPageNumber([1]);
      }
    }
  }, [business]);

  const indexOfLastTodo = pagination.currentPage * pagination.totalItemonPage;
  const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemonPage;
  const currentTodos = (searchKey === "" && mode !== "") ||
    (searchKey !== "" && mode === "") ||
    (searchKey !== "" && mode !== "")
    ? business
    : business.slice(indexOfFirstTodo, indexOfLastTodo);

  const handleClick = (num) => {
    setPagination({
      ...pagination,
      currentPage: num,
    });
  };
  const handleBackward = (num) => {
    if (pageNumber[0] < num) {
      setPagination({
        ...pagination,
        currentPage: num - 1,
      });
    }
  };
  const handleForward = (num) => {
    if (pageNumber[parseInt(pageNumber.length) - 1] > num) {
      setPagination({
        ...pagination,
        currentPage: num + 1,
      });
    } else {
    }
  };

  const checkBusinessUrl = (type) => {
    if (data.url) {
      dispatch(
        onCheckBusinessUrl("businessUrl", data, businessChecker, setBusinessChecker, type, setLoader, navigate)
      );
    }
  };

  useEffect(() => {
    fetchBusiness();
  }, []);

  // unmount component
  useEffect(() => {
    return () => dispatch(onUnmountCompo());
  }, []);

  return (
    <>
      <TitleBar title="Business" />
      <Navbar activeNav={"business"} />
      <section className="siteWrap">
        <div className="container">
          <div className="business-wrap">
            <div className="business-top">
              <h2>Your Business</h2>
              <div>
                <Link className="demoLink mt-0" onClick={handleShow}>
                  Create New Business <FiArrowRight />
                </Link>
              </div>
            </div>
            <div className="business-list">
              <div className="business-search">
                <div className="business-search-inp">
                  <span className="searchIcon">
                    <FiSearch />
                  </span>
                  <input
                    className="inpSearch withIcon"
                    type="text"
                    placeholder="Search business name"
                    onChange={handleSearch}
                  />
                </div>
                <div className="business-search-inp ml-2">
                  <select onChange={(e) => handleMode(e)} className="inpSearch">
                    <option value={""}>All</option>
                    <option value={0}>Sandbox</option>
                    <option value={1}>Live</option>
                  </select>
                </div>
              </div>

              <div className="bListing">
                <table className="table businessTable">
                  <thead>
                    <BusinessTableHeader
                      setBusinessData={setBusinessData}
                      businessData={businessData}
                    />
                  </thead>
                  <tbody>
                    {currentTodos.length > 0
                      ? currentTodos.map((curElem, index) => {
                        let logo = curElem.logo;
                        return (
                          <tr key={index}>
                            <td>
                              <div className="proThumb">
                                <img src={logo} alt="logo" />
                              </div>
                            </td>
                            <td>{curElem.name}</td>
                            <td>
                              {curElem.mode === "live" ? (
                                <span className="pMode live">Live</span>
                              ) : (
                                <span className="pMode sandbox">SandBox</span>
                              )}
                            </td>
                            <td>{curElem.product}</td>
                            <td>
                              <a
                                className="roundBox"
                                href={`https://${curElem.url}`}
                                target="_blank"
                                title="Publish"
                              >
                                <FiExternalLink />
                              </a>


                              <Link
                                className="roundBox"
                                to={`/view-business?bid=${curElem.id}`}
                                title="Edit"
                              >
                                <FiEdit />
                              </Link>
                              <span
                                className="roundBox"
                                onClick={() => deleteBusiness(curElem.id)}
                                title="Delete"
                              >
                                <FiTrash2 />
                              </span>
                            </td>
                            <td>
                              <Dropdown className="tableDrop">
                                <Dropdown.Toggle variant="n">
                                  <img src={dotImg} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <BusinessDropdown
                                    curElem={curElem}
                                    compoType="business"
                                    fetchBusiness={fetchBusiness}
                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </table>
                {loader === true ? (
                  ""
                ) : businessData.length === 0 ? (
                  ""
                ) : currentTodos.length !== 0 ? (
                  ""
                ) : (
                  <div className="text-center mt-4 text-capitalize">
                    "No Data Found"
                  </div>
                )}
                {businessData.length === 0 ? (
                  loader ? (
                    <div
                      className="text-center"
                      style={{ fontSize: "xx-large" }}
                    >
                      <i className="fa fa-spinner fa-spin mx-4" />
                    </div>
                  ) : (
                    <div className="text-center" >
                      <p style={{ color: "#2552af" }}>
                        "No business created yet"
                      </p>
                    </div>
                  )
                ) : (
                  ""
                )}

                <div className="tablepage mx-3">
                  <ul>
                    <li
                      onClick={() => handleBackward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronLeft style={{ color: pageNumber.length <= 1 ? "gray" : "white" }} />
                      </a>
                    </li>
                    {pageNumber.length > 0
                      ? pageNumber.map((curElem, index) => {
                        return (
                          <li
                            key={index}
                            id={curElem}
                            style={{ color: "white" }}
                            onClick={() => handleClick(curElem)}
                            className="cursor-pointer"
                          >
                            <a
                              style={
                                curElem === pagination.currentPage
                                  ? {
                                    backgroundColor: "white",
                                    color: "#400C46",
                                  }
                                  : {}
                              }
                            >
                              {curElem}
                            </a>
                          </li>
                        );
                      })
                      : ""}
                    <li
                      onClick={() => handleForward(pagination.currentPage)}
                      className={pageNumber.length <= 1 ? "not-allowed" : "cursor-pointer"}
                    >
                      <a>
                        <FaChevronRight style={{ color: pageNumber.length <= 1 ? "gray" : "white" }} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal
        className="VideoModal small"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <form action="" onSubmit={(e) => onSubmit(e)}>
            <div onClick={handleClose} className="vidClose">
              <img src={modalCloseIcon} alt="" />
            </div>
            <div className="inpLabelWrap ">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inpLabel">
                    <label className="text-dark" htmlFor="">
                      Business Name
                      <span
                        style={{
                          float: "right",
                          fontweight: "normal",
                          marginRight: "10px",
                        }}
                      >
                        {nameLength} / 100
                      </span>
                    </label>
                    <input
                      className="inpLabel-inp"
                      placeholder="Business Name Here"
                      type="text"
                      name="name"
                      onChange={(e) => handleChange(e)}
                      maxLength={100}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inpLabelWrap ">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inpLabel">
                    <label className="text-dark" htmlFor="">
                      Business Description
                    </label>
                    <textarea
                      className="inpLabel-inp text-dark"
                      name="description"
                      placeholder="Add Description"
                      onChange={(e) => handleChange(e)}
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="inpLabelWrap ">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inpLabel">
                    <label className="text-dark" htmlFor="">
                      Business URL:
                    </label>
                    <input
                      className="inpLabel-inp"
                      style={{
                        border: `1px solid ${businessChecker.checkBusinessUrl === true ? "bed1ff" : "red"}`,
                      }}
                      placeholder="exapmle"
                      type="text"
                      name="url"
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => checkBusinessUrl(e)}
                      onKeyPress={(evt) => [" "].includes(evt.key) && evt.preventDefault()}
                      required
                    />
                    <span className="labelStat text-dark">.fastpaid.io</span>
                  </div>
                  {businessChecker.checkBusinessUrl === true ? (
                    ""
                  ) : (
                    <div class="alert alert-danger mt-2 not-allowed" style={{ color: "red" }} role="alert" >
                      {businessChecker.checkBusinessMessage}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="btn-block inpBtn mt-3 create-modal-btn"
            >
              {loader ? (
                <>
                  Creating Business
                  <i
                    className="fa fa-spinner fa-spin mx-1"
                    style={{ fontSize: "20px" }}
                  />
                </>
              ) : (
                "Create Business"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessTable;
