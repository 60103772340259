import React, { useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  onSubmitDomain,
  addBusinessData,
  onCheckBusinessUrl,
  onGetBusiness,
  addLocalDefault,
  addTimeFormate,
  onSubmitBusiness,
  onUploadBusinessLogo,
  addFormateHours
} from "../../Actions/BusinessAction";
import OpeningHours from "./OpeningHours";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-timezone";
const BusinessGeneral = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = queryString.parse(location.search).bid;
  const state = useSelector((state) => state.business.data);
  const [loader, setLoader] = useState({
    loadLoader: false,
    saveLoader: false,
    mainLoader: false,
  });
  const [domainLoader, setDomainLoader] = useState(false)

  const [timeZone, setTimeZone] = useState(moment.tz.names());
  const [isSubmit, setIsSubmit] = useState(true);

  const [domainData, setDomainData] = useState({
    isDomain: false,
    domainMsg: "",
    domainCheck: true,
    domainBtnType: "Check"
  });

  const [howToDomain, setHowToDomain] = useState(false);

  const [isValidUrl, setIsValidUrl] = useState({
    facebook: true,
    youtube: true,
    instagram: true,
    twitter: true
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "timeFormat") {
      let val = checked ? "24" : "12";
      dispatch(addTimeFormate(val));
      dispatch(addFormateHours(checked, state));
    } else if (name === "mode") {
      let val = checked ? "live" : "sandbox";
      dispatch(addBusinessData(name, val));
    } else if (name === "localDefault") {
      dispatch(addLocalDefault(checked));
    } else if (name === "phone") {
      let val = value;
      val = value.match("^[0-9.]*$");
      if (val) {
        dispatch(addBusinessData(name, value));
      } else {
      }
    }
    else {
      dispatch(addBusinessData(name, value));
    }
    
  };

  const handleDomain = (e) => {
    const { name, checked } = e.target;
    if (name === "isDomain" && !checked) {
      setDomainData({
        ...domainData,
        domainCheck: true,
        domainMsg: "",
        [name]: checked,
      });
      dispatch(addBusinessData("domain", ""));
    } else {
      setDomainData({ ...domainData, [name]: checked });
    }
  };

  const handleHowToDomain = () => {
    setHowToDomain(!howToDomain);
  };

  const validateSocialMediaUrl = (e) => {
    let { name, value } = e.target
    if (value !== "") {
      const facebook = /^(?:https?:\/\/)?(?:www\.)?(?:facebook)\.com\/.*[a-zA-Z]/;
      const youtube = /^(?:https?:\/\/)?(?:www\.)?(?:youtube)\.com\/.*[a-zA-Z]/;
      const instagram = /^(?:https?:\/\/)?(?:www\.)?(?:instagram)\.com\/.*[a-zA-Z]/;
      const twitter = /^(?:https?:\/\/)?(?:www\.)?(?:twitter)\.com\/.*[a-zA-Z]/;
      let result = false;
      switch (name) {
        case "facebook":
          result = facebook.test(value);
          break;
        case "youtube":
          result = youtube.test(value);
          break;
        case "instagram":
          result = instagram.test(value);
          break;
        case "twitter":
          result = twitter.test(value);
          break;
        default:
          result = false
          break;
      }
      setIsValidUrl({ ...isValidUrl, [name]: result })
      setIsSubmit(result)
    }
  };

  const handleSocialMedia = (e) => {
    const { name, checked, value } = e.target;
    if (name === "isSocialMedia" && !checked) {
      dispatch(
        addBusinessData("socialMedia", {
          [name]: checked,
          facebook: "",
          youtube: "",
          instagram: "",
          twitter: "",
        })
      );
      setIsSubmit(true)
      setIsValidUrl({
        facebook: true,
        youtube: true,
        twitter: true,
        instagram: true
      })
    } else if (name === "isSocialMedia") {
      dispatch(
        addBusinessData("socialMedia", { ...state.socialMedia, [name]: checked })
      );
    } else {
      dispatch(
        addBusinessData("socialMedia", { ...state.socialMedia, [name]: value })
      );
    }
  };

  const checkBusinessUrl = (e) => {
    setDomainLoader(true)
    if (domainData.domainBtnType === "Check") {
      const { name } = e.target;
      if (state.domain !== "") {
        let obj
        obj = {
          ...obj,
          url: state.domain
        }
        const dotCount = state.domain.split(".").length - 1;
        if (dotCount === 1 && !state.domain[0].includes(".") && !state.domain[state.domain.length - 1].includes(".")) {
          dispatch(onCheckBusinessUrl(name, obj, domainData, setDomainData, false, setDomainLoader));
        } else {
          setDomainData({ ...domainData, domainCheck: false, domainMsg: "Not More Then One Dot", domainBtnType: "Check" });
          setDomainLoader(false)
        }
      } else {
        setDomainLoader(false)
      }
    } else {
      dispatch(onSubmitDomain(state.id, state.domain, domainData, setDomainData, domainLoader, setDomainLoader));
    }
  };

  const onUploadLogo = (e) => {
    let imageData = e.target.files[0];
    let allowedType = ["image/png", "image/jpg", "image/jpeg"];
    if (imageData) {
      if (allowedType.includes(imageData.type)) {
        if (imageData.size < 5000001) {
          const formData = new FormData();
          formData.append("upload_type", "logo");
          formData.append("file", imageData);
          setLoader({
            ...state,
            loadLoader: true,
          });
          dispatch(onUploadBusinessLogo(formData, loader, setLoader));
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Max allowed size for image is 5MB.",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please select a valid file type.",
        });
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!state.socialMedia.isSocialMedia || state.socialMedia.facebook !== "" || state.socialMedia.instagram !== "" || state.socialMedia.youtube !== "" || state.socialMedia.twitter !== "") {
      if (isSubmit) {
        setLoader({
          ...loader,
          saveLoader: true,
        });
        dispatch(onSubmitBusiness(loader, setLoader));
      }
    }
    else {
      setIsValidUrl({
        facebook: false,
        youtube: false,
        twitter: false,
        instagram: false
      })
    }
  };

  const fetchBusiness = () => {
    let data = {
      id: id,
    };
    dispatch(onGetBusiness(data, navigate, setLoader, loader));
  };
  useEffect(() => {
    if (id) {
      setLoader({
        ...loader,
        mainLoader: true,
      });
      fetchBusiness();
    }
  }, [id]);

  useEffect(() => {
    if (state.domain !== "") {
      setDomainData({ ...domainData, isDomain: true });
    }
  }, [state]);

  return (
    <>
      {loader.mainLoader ? (
        <div className="loader-sec">
          <div className="loader"></div>
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <div className="productTabs-main">
            <div className="row">
              <div className="col-lg-6">
                <div className="inpLabelWrap mt-0">
                  <span className="labelTxt">Name</span>
                  <div className="inpLabel">
                    <label htmlFor="">Name:
                      <span
                        style={{
                          float: "right",
                          fontweight: "normal",
                          marginRight: "10px",
                        }}
                      >
                        {state.name.length} / 100
                      </span>
                    </label>

                    <input
                      className="inpLabel-inp"
                      placeholder="Your Name Here"
                      type="text"
                      name="name"
                      value={state.name}
                      onChange={(e) => handleChange(e)}
                      maxLength={100}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="inpLabelWrap mt-0">
                  <span className="labelTxt">Phone</span>
                  <div className="inpLabel">
                    <label htmlFor="">Phone:
                      <span
                        style={{
                          float: "right",
                          fontweight: "normal",
                          marginRight: "10px",
                        }}
                      >
                        {state.phone.length} / 15
                      </span>
                    </label>
                    <input
                      className="inpLabel-inp"
                      placeholder="Your Phone Here"
                      type="phone"
                      name="phone"
                      value={state.phone}
                      onChange={(e) => handleChange(e)}
                      required
                      maxLength={15}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inpLabelWrap">
              <span className="labelTxt">Email</span>
              <div className="inpLabel">
                <label htmlFor="">Email:</label>
                <input
                  className="inpLabel-inp"
                  placeholder="Your Email Here"
                  type="email"
                  name="email"
                  value={state.email}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="inpLabelWrap">
                  <span className="labelTxt">Description</span>
                  <div className="inpLabel">
                    <label htmlFor="description">Description:</label>
                    <textarea
                      className="inpLabel-inp"
                      name="description"
                      id="description"
                      placeholder="Add A Description"
                      value={state.description}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="inpLabelWrap">
                  <span className="labelTxt">Address</span>
                  <div className="inpLabel">
                    <label htmlFor="">Address:</label>
                    <textarea
                      className="inpLabel-inp"
                      name="address"
                      placeholder="Your Address Here"
                      value={state.address}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="locateBox full">
                  <div className="pr-3">Local Time Zone</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="localDefault"
                      checked={+state.localDefault}
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="locateBox full">
                  <div className="pr-3">24-hour format (13:00)</div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="timeFormat"
                      checked={state.timeFormat === "24" ? true : false}
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            {+state.localDefault ? (
              ""
            ) : (
              <div className="inpLabelWrap">
                <span className="labelTxt">Timezone</span>
                <div className="inpLabel">
                  <label htmlFor="">Timezone:</label>
                  <select
                    className="inpLabel-inp"
                    name="timezone"
                    value={state.timezone}
                    onChange={(e) => handleChange(e)}
                  >
                    <option value="">Select Timezone</option>
                    {timeZone.map((ele) => (
                      <option key={ele} value={ele}>
                        {ele}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="openHr">
              <div className="openHr-left">
                <label htmlFor="">Opening hours:</label>
              </div>
              <div className="openHr-right">
                {state.openingHour
                  ? state.openingHour.map((curElem, index) => {
                    return (
                      <React.Fragment key={index}>
                        <OpeningHours
                          curElem={curElem}
                          index={index}
                          state={state}
                          format={state.timeFormat}
                        />
                      </React.Fragment>
                    );
                  })
                  : ""}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="inpLabelWrap">
                  <span className="labelTxt">Business URL</span>
                  <div className="inpLabel">
                    <label htmlFor="">Business URL:</label>
                    <input
                      className="inpLabel-inp"
                      type="text"
                      readOnly
                    />
                    <span className="labelStat" style={{ left: "20px" }}>https://{state.url}</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="inpLabel d-flex justify-content-between line">
                  <label htmlFor="check-domain">Addon Domain</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="isDomain"
                        checked={domainData.isDomain}
                        onChange={(e) => handleDomain(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>

                {domainData.isDomain ? (
                  <div className="inpLabelWrap ">
                    <div className="row ">
                      <div className="col-lg-9">
                        <div className="inpLabel">
                          <label htmlFor="domainName">Addon Domain:</label>
                          <input
                            className="inpLabel-inp"
                            placeholder="Enter Addon Domain"
                            style={{
                              border: `1px solid ${domainData.domainCheck === true ? "blue" : "red"
                                }`,
                            }}
                            type="text"
                            name="domain"
                            value={state.domain}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <button type="button" className="demoLink m-2" name="domain" onClick={(e) => checkBusinessUrl(e)} >{domainLoader ? <i className="fa fa-spinner fa-spin mx-1" style={{ fontSize: "15px" }} /> : ""}{domainData.domainBtnType}</button>
                      </div>
                    </div>

                    {domainData.domainCheck === true ? (
                      ""
                    ) : (
                      <span style={{ color: "red" }}>
                        {domainData.domainMsg}
                      </span>
                    )}
                    {
                      <span
                        style={{
                          cursor: "pointer",
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "#003297",
                          fontFamily: "poppins,Sans-serif",
                          marginLeft: "10px",
                        }}
                        onClick={() => handleHowToDomain()}
                      >
                        How to Add a Custom Domain
                      </span>
                    }

                    {howToDomain ? (
                      <>
                        <div className="add_on_domain">
                          <ul className="list_domain">
                            <li>
                              Please use top-level domains only (sub-domains
                              won’t work).
                            </li>
                            <li>
                              Please enter your domain above without any www, http or https (domain.com for example).
                            </li>
                            <li>
                              Enter your domain name for example "mydomain.com"
                              (without the quotation marks) and click the "Add
                              Domain" button.
                            </li>
                            <li>
                              Copy this IP address for the A Record:
                              72.52.158.199
                            </li>
                            <li>
                              Log into your domain registrar account and find
                              the option to add Host Records, which can usually
                              be found in the Advanced DNS section. If you're
                              struggling to find this, perform a Google search
                              for "[Domain Registrar Here] how to change host
                              records".
                            </li>
                            <li>Select your type as "A Record".</li>
                            <li>Set Host as "@".</li>
                            <li>
                              Paste the copied IP Address from step 4 into the
                              value field.
                            </li>
                            <li>Leave TTL as Automatic or 14400.</li>
                            <li>Click Save and you're done.</li>
                          </ul>
                          <span>
                            Note: It can take up to 48-72 hours to reflect.
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="inpLabel d-flex justify-content-between line">
                  <label htmlFor="check-domain">Social Media</label>
                  <div className="d-flex">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="isSocialMedia"
                        checked={state.socialMedia.isSocialMedia}
                        onChange={(e) => handleSocialMedia(e)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>

                {state.socialMedia.isSocialMedia ? (
                  <div>
                    <div className="col-lg-12">
                      <div className="inpLabelWrap">
                        <div className="inpLabel">
                          <label htmlFor="">Facebook:</label>
                          <input
                            className="inpLabel-inp"
                            name="facebook"
                            style={{ border: isValidUrl.facebook ? '1px solid blue' : '1px solid red' }}
                            placeholder="facebook url"
                            type="text"
                            value={state.socialMedia.facebook}
                            onChange={(e) => handleSocialMedia(e)}
                            onBlur={(e) => validateSocialMediaUrl(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ fontSize: "13px", fontWeight: "600", marginLeft: "18px" }}>{isValidUrl.facebook ? '' : 'Incorrect url'}</div>
                    <div className="col-lg-12">
                      <div className="inpLabelWrap">
                        <div className="inpLabel">
                          <label htmlFor="">Youtube:</label>
                          <input
                            className="inpLabel-inp"
                            name="youtube"
                            style={{ border: isValidUrl.youtube ? '1px solid blue' : '1px solid red' }}
                            placeholder="youtube url"
                            type="text"
                            value={state.socialMedia.youtube}
                            onChange={(e) => handleSocialMedia(e)}
                            onBlur={(e) => validateSocialMediaUrl(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ fontSize: "13px", fontWeight: "600", marginLeft: "18px" }}>{isValidUrl.youtube ? '' : 'Incorrect url'}</div>
                    <div className="col-lg-12">
                      <div className="inpLabelWrap">
                        <div className="inpLabel">
                          <label htmlFor="">Instagram:</label>
                          <input
                            className="inpLabel-inp"
                            name="instagram"
                            style={{ border: isValidUrl.instagram ? '1px solid blue' : '1px solid red' }}
                            placeholder="instagram url"
                            type="text"
                            value={state.socialMedia.instagram}
                            onChange={(e) => handleSocialMedia(e)}
                            onBlur={(e) => validateSocialMediaUrl(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ fontSize: "13px", fontWeight: "600", marginLeft: "18px" }}>{isValidUrl.instagram ? '' : 'Incorrect url'}</div>
                    <div className="col-lg-12">
                      <div className="inpLabelWrap">
                        <div className="inpLabel">
                          <label htmlFor="">Twitter:</label>
                          <input
                            className="inpLabel-inp"
                            name="twitter"
                            style={{ border: isValidUrl.twitter ? '1px solid blue' : '1px solid red' }}
                            placeholder="twitter url"
                            type="text"
                            value={state.socialMedia.twitter}
                            onChange={(e) => handleSocialMedia(e)}
                            onBlur={(e) => validateSocialMediaUrl(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ fontSize: "13px", fontWeight: "600", marginLeft: "18px" }}>{isValidUrl.twitter ? '' : 'Incorrect url'}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div
                  className="inpLabel"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="col-6">
                    <label className="mb-2" htmlFor="">
                      Upload Logo
                    </label>
                    <div className="style-upload-box">
                      <input
                        className="custom-file-input"
                        type="file"
                        onChange={(e) => onUploadLogo(e)}
                      />
                      <div>
                        <AiOutlineCloudUpload />
                        <h6>"Upload your logo"</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-center align-items-center">
                    <div className="business_image_wrapper">
                      {loader.loadLoader ? (
                        <div className="loader_img">
                          <i
                            className="fa fa-spinner fa-spin mr-2"
                            style={{ fontSize: "30px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <img src={state.logo} alt="business logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" inpLabel d-flex locateBox justify-content-between">
              <label htmlFor="">Mode</label>
              <div className="d-flex">
                <label className="switch flex">
                  <input
                    type="checkbox"
                    name="mode"
                    checked={state.mode === "live" ? true : false}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="slider round"></span>
                  <span className="slClose">Sandbox</span>
                  <span className="slOpen">Live</span>
                </label>
              </div>
            </div>

            <div className="btn-sec text-right">
              <button className="demoLink" type="submit">
                {loader.saveLoader ? (
                  <>
                    Updating{" "}
                    <i
                      className="fa fa-spinner fa-spin mx-1"
                      style={{ fontSize: "15px" }}
                    />
                  </>
                ) : (
                  "Update"
                )}{" "}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default BusinessGeneral;
